import React from 'react'

const Heading = ({ addedClasss, children }) => {
  return (
    <h1
      className={`text-5xl font-lucky tracking-wider mb-4 uppercase mt-6 ${addedClasss}`}
    >
      {children}
    </h1>
  )
}

export default Heading
