import React, { useState } from 'react'
const AIQRCodePage = () => {
  const qrCodeData = [
    {
      imageUrl: './assets/projects/ai/qrbtf_QRcode_g1-optimised.webp',
      prompt:
        'An animated, semi-cartoonish illustration of a man sitting at a curved, polished dark wooden desk. The man is dressed in a white shirt and a black hat, with a neatly shaped sharp black beard, holding a cup of tea in one hand. On the desk, there is an open laptop, a small stack of books, drawing attention to the man and the desk, with smooth shading and soft colors creating a professional and approachable atmosphere.',
      url: 'https://qrbtf.com/',
      details: {
        controlStrength: 0.51,
        restorationRate: 1.5,
        paddingRatio: 0.05,
        correctLevel: '30%',
      },
    },
    {
      imageUrl: './assets/projects/ai/quickqr_art.webp',
      prompt:
        'Jewish man sitting at a modern wooden desk, wearing a black hat, and black beard, holding a cup of tea, in a bright office with windows overlooking a green landscape with trees, minimalist art style, warm lighting, cartoon-like aesthetic, cozy and professional atmosphere. happy, smiling vibes.',
      url: 'https://QuickQR.art/app/generate',
      details: {
        model: 'QRArt v5s+',
        style: 'Comic Book',
        QRCodeVisibility: 1.5,
        seed: 3070001131171483,
        balanceSteps: 20,
      },
    },
    {
      imageUrl: './assets/projects/ai/waterfall_qr_qrblend-optimised.webp',
      url: 'https://qrblend.com',
      details: {
        category: 'Nature',
        image: 'Waterfall',
      },
    },
    {
      imageUrl: './assets/projects/ai/gooey.ai-optimised.webp',
      prompt:
        'A young Caucasian man with short dark hair and a beard, wearing a green sweater, sitting at a wooden desk in a cozy home office environment with a window overlooking a scenic landscape in the background',
      url: 'https://gooey.ai/qr-code',
      details: {
        // controlStrength: 0.45,
        // restorationRate: 1.3,
        // paddingRatio: 0.08,
        // correctLevel: '25%',

        model: 'DreamShaper (Lykon)',
        quality: 80,
        artisticPressure: 10,
        qrCodeScale: 0.9,
        useImageBase: 'true',
        imageStrength: 0.5,
        tiling: 0.1,
      },
    },

    // Add more QR code data here
  ]
  const [currentQRCodeIndex, setCurrentQRCodeIndex] = useState(0)
  const currentQRCode = qrCodeData[currentQRCodeIndex]
  const handlePrev = () => {
    setCurrentQRCodeIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : qrCodeData.length - 1
    )
  }
  const handleNext = () => {
    setCurrentQRCodeIndex((prevIndex) =>
      prevIndex < qrCodeData.length - 1 ? prevIndex + 1 : 0
    )
  }
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center py-12">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-lg w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">
          AI Generated QR Code
        </h1>
        <div className="mb-6">
          <img
            src={currentQRCode.imageUrl}
            alt="AI Generated QR Code"
            className="w-full rounded-md"
          />
        </div>
        <div className="mb-4">
          <p className="text-gray-700">
            <span className="font-semibold">{'Prompt: '}</span>
            {currentQRCode.prompt ?? 'No prompt'}
          </p>
        </div>
        <div className="mb-4">
          <p className="text-gray-700">
            <span className="font-semibold">{'URL: '}</span>
            <a
              href={currentQRCode.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              {currentQRCode.url}
            </a>
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {Object.entries(currentQRCode.details).map(([key, value]) => (
            <div key={key}>
              <p className="text-gray-700">
                <span className="font-semibold">{key}: </span> {value}
              </p>
            </div>
          ))}
        </div>
        <div className="flex justify-between mt-4">
          <button
            onClick={handlePrev}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
export default AIQRCodePage
