import { Link } from 'wouter'

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className="min-h-[10vh] text-gray-600 body-font max-w-screen-lg flex items-end justify-center mx-auto">
      <div className="bg-gray-100">
        <div className="py-4 px-5">
          <p className="text-gray-500 text-sm"></p>
          <Link
            onClick={scrollToTop}
            to={'/about'}
            className={`hover:text-gray-700 text-gray-500 text-sm`}
          >
            {`© ${new Date().getFullYear()} Meir Knapp`}
          </Link>
        </div>
      </div>
    </footer>
  )
}
export default Footer
