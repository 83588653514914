import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import GoogleAnalytics from "./utils/GoogleAnalytics";
import DOMCleanup from "./utils/DOMCleanup";
// import PageSetup from "./utils/PageSetup.js";

// import router from "./routes";
// import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import OfflineMessage from "./parts/offlineMessage";
import { Router } from 'wouter'

// import "./i18n.js";

// eslint-disable-next-line no-unused-vars
// import { BrowserRouter } from "react-router-dom";

import App from './App.js'
// import TechnicalInformation from "./pages/SharePage/TechnicalInformation";
// import MainSharePage from "./pages/SharePage/MainSharePage";
// import LoadingPage from "./parts/LoadingPage/loading";

// const root = ReactDOM.createRoot(document.body);
const root = ReactDOM.createRoot(document.getElementById('root'))

const isProduction = process.env.NODE_ENV === 'production'

root.render(
  <React.StrictMode>
    <Router>
      <DOMCleanup />
      <App />
      {isProduction ? <GoogleAnalytics /> : null}
      {isProduction ? <Analytics /> : null}
      {isProduction ? <SpeedInsights /> : null}
      <OfflineMessage />
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
