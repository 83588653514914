import React, { useEffect, useState, useRef } from 'react'
import Heading from '../../components/Heading'
// import { FaArrowDown, FaArrowUp } from 'react-icons/fa' // Import arrow icons from react-icons

const Experiences = ({
  experienceList,
  handleVisitSite,
  setClickedImageId,
  clickedImageId,
  isUserScrolling,
  scrollContainerRefs,
  setHoveredImageId,
}) => {
  const [highlightedSection, setHighlightedSection] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [isReversed, setIsReversed] = useState(true) // State to track if the list is reversed
  const sectionRefs = useRef([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setHighlightedSection(entry.target.id)
          }
        })
      },
      {
        threshold: 0, // Adjust this value to determine when to highlight
        rootMargin: `-${window.innerHeight / 2}px 0px -${
          window.innerHeight / 2
        }px 0px`,
        root: null,
      }
    )

    const currentRefs = sectionRefs.current // Copy to a local variable
    currentRefs.forEach((section) => {
      if (section) observer.observe(section)
    })

    return () => {
      currentRefs.forEach((section) => {
        // Use the local variable in cleanup
        if (section) observer.unobserve(section)
      })
    }
  }, [])

  // Function to toggle the order of the experiences
  // const toggleOrder = () => {
  //   setIsReversed(!isReversed)
  // }

  // Sort the experienceList based on the isReversed state
  const sortedExperienceList = [...experienceList].sort((a, b) => {
    if (isReversed) {
      return -1 // Reverse the order
    } else {
      return 1 // Maintain original order
    }
  })

  return (
    <>
      <section className={`mb-16`}>
        <div className="flex flex-col items-center mb-6 justify-center">
          <Heading>My Journey</Heading>
          {/* <button onClick={toggleOrder} className="relative flex group">
            <div className="group-hover:text-blue-600 ml-2 text-slate-600 text-sm ">
              {isReversed ? 'Newest to Oldest' : 'Oldest to Newest'}
            </div>
            <div className=" ml-2 bg-blue-500 text-white px-2 py-1 rounded-full hover:bg-blue-600 transition-colors duration-200">
              {!isReversed ? <FaArrowUp /> : <FaArrowDown />}
            </div>
          </button> */}
        </div>
        <div className="flex flex-col items-center">
          {sortedExperienceList.map((item, index) => (
            <div
              id={`section-${index}`} // Add an ID for each section
              ref={(el) => (sectionRefs.current[index] = el)} // Assign ref to each section
              className={`mb-8 rounded shadow bg-white max-w-[800px] 
                flex flex-col xs:flex-row w-full border hover:border-green-400 
                items-stretch h-full ${
                  highlightedSection === `section-${index}`
                    ? 'shadow shadow-green-300'
                    : ''
                }`}
              key={item.id}
              onMouseEnter={() => setHoveredImageId(item.id)}
              onMouseOver={() => setHoveredImageId(item.id)}
              onMouseLeave={() => {
                setHoveredImageId(null)
                isUserScrolling.current = false
              }}
            >
              {!item.isWebsite && item.screenshot && (
                <div
                  className={`overflow-y-scroll scrollbar-hide self-end
                      max-h-[260px] transition-all duration-300
                      w-full xs:w-1/3 flex-shrink-0 rounded-b xs:rounded-b-none xs:rounded-l 
                      `}
                >
                  <img
                    src={item.longImage ? item.longImage : item.screenshot}
                    alt={item.title}
                    draggable={false}
                    className={`w-full select-none touch-none pointer-events-none object-cover object-top`}
                  />
                </div>
              )}
              <div className="flex flex-col p-4 items-center relative justify-center">
                <h3 className="font-semibold text-lg mb-1 pt-2">
                  {item.title}
                </h3>
                <p className=" text-base text-slate-700 self-start">
                  {item.description}
                </p>
                {item.projectLink && (
                  <a
                    href={item.projectLink}
                    onClick={(e) => {
                      e.preventDefault()
                      handleVisitSite(item.projectLink, item.title, item.id)
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative text-green-500 border border-green-500 hover:bg-green-400 rounded-md px-4 py-2 cursor-pointer inline-flex items-center transition-all duration-200 hover:text-white mt-2"
                  >
                    {item.title === 'VR 360 Action / OVRDRV Games CTO'
                      ? 'View Resume'
                      : 'Visit Site'}
                  </a>
                )}
                {item.note && (
                  <p className="text-sm text-slate-500 mt-1">{item.note}</p>
                )}
                {item.project_description && (
                  <p className="text-base text-slate-600 mt-2">
                    {item.project_description}
                  </p>
                )}
                <span className="text-slate-400 text-sm px-2 py-1 absolute top-0 left-0 select-none">
                  {item.date}
                </span>
                <span className="text-slate-400 text-sm px-2 py-1 absolute top-0 right-0 select-none">
                  {item.icon}
                </span>
              </div>
              {item.isWebsite && (item.longImage || item.screenshot) && (
                <div
                  onClick={() => {
                    setClickedImageId(
                      clickedImageId === item.id ? null : item.id
                    )
                  }}
                  onMouseLeave={() => {
                    setClickedImageId(null)
                    isUserScrolling.current = false
                  }}
                  ref={(el) => (scrollContainerRefs.current[item.id] = el)}
                  className={`overflow-y-scroll scrollbar-hide self-end
                        max-h-[260px] transition-all duration-300
                        w-full xs:w-1/3 flex-shrink-0 rounded-b xs:rounded-b-none xs:rounded-r 
                        `}
                >
                  <img
                    src={item.longImage ? item.longImage : item.screenshot}
                    alt={item.title}
                    draggable={false}
                    className={`w-full select-none touch-none pointer-events-none object-cover object-top`}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default Experiences
