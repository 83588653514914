export const trackEvent = ({ eventName, text, category }) => {
  if (typeof window !== 'undefined' && window.gtag) {
    const now = new Date()
    const hour = now.getHours()
    const dayOfWeek = now.getDay() // 0 for Sunday, 6 for Saturday
    const deviceType = /Mobi|Android/i.test(navigator.userAgent)
      ? 'mobile'
      : 'desktop'
    const referrer = document.referrer || 'direct'

    window.gtag('event', eventName, {
      event_category: category,
      event_label: text,
      value: 1,
      click_hour: hour,
      click_day: dayOfWeek,
      device_type: deviceType,
      agent: navigator.userAgent,
      referrer: referrer,
    })
  } else console.error('no window.gtag found')
}
