import React, { useEffect, useRef } from 'react'
import {
  Code,
  Server,
  Flag,
  Database,
  GitBranch,
  // eslint-disable-next-line no-unused-vars
  ArrowUpRight,
  // eslint-disable-next-line no-unused-vars
  ChevronRight,
  Download,
  BarChart,
  Shield,
} from 'lucide-react'
import { ContactBody } from '../Contact'

const SKILLS = {
  programming: {
    icon: Code,
    title: 'Programming Languages',
    items: ['Python', 'JavaScript', 'Node.js', 'PHP', 'React'],
  },
  frameworks: {
    icon: Server,
    title: 'Frameworks & Libraries',
    items: [
      'TensorFlow',
      'PyTorch',
      'scikit-learn (learning)',
      'Express JS',
      'GraphQL',
      'WebSocket',
      'Socket.IO',
      'Tailwind CSS',
      'WebRTC',
      '2FA / OTP',
      'REST',
      'API Integration',
    ],
  },
  dataScience: {
    icon: BarChart,
    title: 'Data Science & AI',
    items: [
      'Machine Learning',
      'Natural Language Processing',
      'Data Analysis',
      'Data Visualization',
      'Model Deployment',
    ],
  },
  database: {
    icon: Database,
    title: 'Database Technologies',
    items: ['MongoDB', 'MySQL', 'PostgreSQL', 'Google Sheets'],
  },
  versionControl: {
    icon: GitBranch,
    title: 'Version Control',
    items: ['GitHub Workflows', 'GitHub', 'GitLab'],
  },
  security: {
    icon: Shield,
    title: 'Security & Cybersecurity',
    items: [
      'Cloudflare',
      'CAPTCHAs',
      'Backend Hardening',
      'Network Security',
      'Data Encryption',
      'JWT Authentication',
    ],
  },
  languages: {
    icon: Flag,
    title: 'Languages',
    items: [
      'English (Native)',
      'Hebrew (Native)',
      'Yiddish (Basic)',
      'French / Chinese / Japanese (Elementary)',
    ],
  },
}

const Header = () => {
  return (
    <>
      <header className="mb-6 items-center flex flex-col">
        <h1 className="text-3xl text-gray-800 font-lucky tracking-widest">
          MEIR KNAPP
        </h1>
        <div className="flex flex-wrap gap-4 text-gray-600 items-center justify-evenly my-4">
          <div className="flex items-center">
            <span className="mr-1 select-none">📍</span>
            <span>
              Location: <span className="underline">NY</span>
            </span>
          </div>
          <span className="select-none">|</span>
          <div className="flex items-center">
            <span className="mr-1 select-none">📧</span>
            <a
              href="mailto:hello@meir.pro"
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-mantis-600 transition-colors"
            >
              hello@meir.pro
            </a>
          </div>
          <span className="select-none">|</span>
          <div className="flex items-center">
            <span className="mr-1 select-none">🌐</span>
            <a
              href="https://meir.pro"
              className="underline hover:text-mantis-600 transition-colors"
            >
              meir.pro
            </a>
          </div>
          <span className="select-none">|</span>
          <div className="flex items-center">
            <span className="mr-1 select-none">💼</span>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/meir-k/"
              className="underline hover:text-mantis-600 transition-colors"
            >
              LinkedIn
            </a>
          </div>
        </div>
        <p className="text-gray-600 items-center flex flex-wrap">
          AI Technology Leader | Full-Stack Developer | Systems Engineer
        </p>
      </header>
    </>
  )
}
const ProfessionalExperience = () => {
  return (
    <>
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3 border-b pb-2">
          Professional Experience
        </h2>
        <div>
          <h3 className="font-bold text-lg">CTO | VR 360 Action</h3>
          <h2 className="text-gray-500">2018 - Dec 2024</h2>
          <p className="py-4">
            TODO, figure what at vr360action I did that is relevant for AI.
          </p>
          <ul className="list-disc list-inside text-gray-700 text-sm space-y-2 mb-4">
            <li>
              <strong>Systems Optimization:</strong> Developed a customized
              Windows OS installation package that significantly reduced machine
              setup time by 80-90%. This enabled faster team deployment and
              standardized machine configurations across the organization.
            </li>
            <li>
              <strong>Communication Infrastructure:</strong> Designed and
              implemented a comprehensive API protocol, facilitating seamless
              communication between multiple developer teams. This established a
              unified information exchange system, enhancing project
              coordination and reducing communication overhead.
            </li>
            <li>
              <strong>Collaboration:</strong> Worked closely with data
              scientists and software engineers to deploy AI models into
              production, utilizing cloud services for scalability.
            </li>
          </ul>
          <span className="pt-8">
            Promoted from Technician/Engineer to Chief Technology Officer (CTO).
          </span>
        </div>
      </section>
    </>
  )
}
const TechnicalExpertise = () => {
  return (
    <>
      <h2 className="text-2xl font-bold mb-6 border-b pb-3">
        Technical Expertise
      </h2>

      {/* Replace the skills section with: */}
      {Object.values(SKILLS).map(({ icon: Icon, title, items }) => (
        <div key={title} className="mb-6">
          <h3 className="font-semibold text-lg mb-2 flex items-center justify-between transition-all duration-200 hover:translate-x-2 hover:text-opacity-80">
            <span className="mr-2">{title}</span>
            <Icon className="mr-2" size={20} />
          </h3>
          <ul className=" text-sm list-disc flex flex-wrap ">
            {items.map((item) => (
              <li key={item} className="ml-6">
                {item}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}

const Education = () => {
  return (
    <>
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3 border-b pb-2">Education</h2>
        <div className="space-y-4">
          <div>
            <h3 className="font-bold text-lg">Master's in Data Science</h3>
            <p className="text-gray-500">
              University of Technology | 2021-2023
            </p>
            <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
              <li>
                Focus on machine learning, data analysis, and AI applications.
              </li>
              <li>
                Completed projects on predictive modeling and natural language
                processing.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

const ResumeAI = () => {
  const targetRef = useRef()

  useEffect(() => {
    document.title = 'Meir Knapp - Resume 2024'
  }, [])
  // Add useEffect for print event listeners
  useEffect(() => {
    const originalStyle = document.body.style.cssText
    const handleBeforePrint = () => {
      // Store current body style
      // Hide all other elements
      document.body.style.cssText = `
        visibility: hidden;
        position: relative;
      `

      // Make resume visible with added margin for printing
      targetRef.current.style.visibility = 'visible'
      targetRef.current.style.position = 'absolute'
      targetRef.current.style.left = '0'
      targetRef.current.style.top = '0'
      targetRef.current.style.margin = '0'
      targetRef.current.style.padding = '0 0'
    }

    const handleAfterPrint = () => {
      // Restore original styles
      document.body.style.cssText = originalStyle
      targetRef.current.style.position = ''
      targetRef.current.style.left = ''
      targetRef.current.style.top = ''
      targetRef.current.style.margin = ''
    }

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <div className="flex flex-col items-center">
        {/* relative content-center items-center */}
        {/* <div className="mb-4 mx-auto"> */}
        <button
          onClick={handlePrint}
          className="bg-mantis-600 hover:bg-mantis-700 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center"
        >
          <Download className="mr-2" size={20} />
          Print/Save Resume
        </button>
        {/* </div> */}

        <div className="self-start lg:content-center scrollbar-hide w-screen overflow-auto">
          <div
            ref={targetRef}
            id="resume"
            //width 150vw (on phones only) - 1/3 goes to sidebar, 2/3 goes to content, leaving content with 100vw
            className="w-[150vw] md:w-[1000px] flex bg-white border print:border-none print:shadow-none my-8 rounded-lg print:rounded-none"
          >
            {/* Skills Sidebar */}
            <div className="w-1/3 bg-mantis-600 text-white p-6 print:bg-mantis-600 rounded-l-lg print:rounded-none print:text-white print:[-webkit-print-color-adjust:exact] print:[print-color-adjust:exact] print:h-[100vh]">
              <TechnicalExpertise />
            </div>
            {/* Main Content */}
            <div className="w-2/3 md:min-w-min p-8">
              <Header />

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-3 border-b pb-2">
                  Professional Summary
                </h2>
                <p className="text-gray-700">
                  Technology professional with expertise in AI and machine
                  learning, specializing in developing and deploying intelligent
                  systems that enhance operational efficiency and user
                  experience. Proven ability to leverage data-driven insights to
                  drive strategic decision-making and improve product features.
                </p>
              </section>

              <ProfessionalExperience />

              <Education />

              {/* Removed Websites section as it may not be relevant */}
            </div>
          </div>
        </div>

        <div className="mt-20">
          <ContactBody />
        </div>
      </div>
    </>
  )
}

export default ResumeAI
