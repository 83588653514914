// ContactSection.js
import React from 'react'
import Heading from '../../components/Heading'
import { AnimatedContactButton } from '../Contact'

const ContactSection = () => {
  return (
    <section className="mb-2 text-center">
      <Heading>Let's Connect</Heading>
      <p className="text-lg text-slate-700 mb-8">
        I'm always eager to explore new opportunities. If you're interested to
        work together, or just chat, let me know!
      </p>
      <AnimatedContactButton />
    </section>
  )
}

export default ContactSection
