import React from 'react'
import Heading from '../../components/Heading'

const OtherProjectsSection = () => {
  const projects = [
    {
      title: 'Anime Search Telegram Bot',
      date: '2018',

      description:
        '🔍 A Telegram bot that allows users to search for anime using two different APIs and gathers the data to display using the Telegram Inline Search API. Written in Node.js, it reached 5000+ users in the database last I checked before shutting it down, all through natural sharing. Since then, similar bots have taken its place. 🤖',
      technologies: [
        'Node.js',
        'Telegram Bot API',
        'Kitsu.io API',
        'AniList API',
      ],
      image: './assets/projects/telegram/AnimeFinder.webp',
    },
    {
      title: 'Progress Tracker Telegram Bot',
      date: '2020',

      description:
        '📊 A Telegram bot that lets users track their progress on subjects they are interested in. Written in Python, it provides a user-friendly interface for monitoring and updating progress. 📈',
      technologies: ['Python', 'Telegram Bot API'],
      image: './assets/projects/telegram/timebot.webp',
    },
    {
      title: 'Quick Help Support Bot',
      date: '2020',
      description:
        '🔧 A Telegram bot designed to provide quick help support for VR 360 Action machines, serving as the first step before reaching out to tech support. Written in Node.js, it streamlines the support process and improves user satisfaction. 🤖',
      technologies: ['Node.js', 'Telegram Bot API'],
      image: './assets/projects/telegram/VRSupportBot.webp',
    },
    {
      title: 'ShamorVeZachor Bot',
      date: '2022',

      description:
        '📅 A Telegram bot that sends daily time notifications based on the Jewish calendar. It employs multiple APIs and techniques to provide accurate prayer times for each location. Users can customize the language 🇺🇸, location 📍, and timezone 🌍 settings to receive personalized updates. The bot uses OpenStreetMap to display maps and utilize geographical information. 🗺️',
      technologies: ['Node.js', 'Telegram Bot API', 'OpenStreetMap'],
      image: './assets/projects/telegram/ShamorVeZachor.webp',
    },
    {
      title: '3D Printing Enthusiast',
      date: '2024',
      description:
        'Exploring the creative possibilities of 3D printing over the past year, producing custom items such as stencils, monograms, and logos for neighbors and local businesses. Passionate about designing and delivering personalized solutions, combining technical precision with artistic creativity.',
      technologies: ['3D Printing', 'STL Designs', 'G-Code'],
      image: './assets/projects/other/chess3d.webp',
    },
    // Add more projects here if needed
  ]

  return (
    <section id="projects" className="bg-gray-100 py-20">
      <div className="px-4">
        <Heading className="">Other Projects</Heading>
        <div className="grid md:grid-cols-27 gap-8">
          {projects
            .sort((a, b) => b.date - a.date)
            .map((project, index) => (
              <div
                key={index}
                className="bg-white rounded-lg flex md:flex-row flex-col items-center outline outline-green-100 hover:outline-2 hover:outline-green-300  h-full  relative justify-center"
              >
                {project.image && (
                  <img
                    src={project.image}
                    alt={project.title}
                    className="mt-4 md:mt-0 flex-shrink-0 max-w-44 md:max-w-none w-full md:w-1/4 aspect-square object-cover rounded-md md:rounded-l-lg md:rounded-none"
                  />
                )}
                <div className="p-4 flex-grow md:relative h-full justify-center items-center flex flex-col">
                  <h3 className="text-xl font-semibold mb-2">
                    {project.title}
                  </h3>
                  <p className="text-gray-700 mb-4">{project.description}</p>
                  <div className="flex justify-center ">
                    {project.technologies.map((tech, techIndex) => (
                      <span
                        key={techIndex}
                        className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 flex items-center"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                  <span className="text-slate-400 text-sm px-2 py-1 absolute top-0 left-0 select-none">
                    {project.date}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default OtherProjectsSection
