import React, { useState } from 'react'
import './AccordionList.css' // Import the CSS file

const AccordionList = ({ items, pageThemeColor = 'green' }) => {
  const [openIndex, setOpenIndex] = useState(null)
  const [isHovered, setIsHovered] = useState(false) // Add state for hover

  // Set the CSS variable dynamically
  React.useEffect(() => {
    document.documentElement.style.setProperty(
      '--page-theme-color',
      pageThemeColor
    )
  }, [pageThemeColor])

  const toggleAccordion = (category, index) => {
    const key = `${category}-${index}`
    const currentIndex = openIndex === key ? null : key
    setOpenIndex(currentIndex)
  }

  const groupedItems = items
    // .filter((item) => !item.hidden || secretKey === unhideKey) // Filter out hidden items based on secretKey
    .reduce((acc, item) => {
      const category = item.tags ? item.tags[0] : '' // Group by the first tag or 'Other'
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(item)
      return acc
    }, {})

  return (
    <div className={`p-4 max-w-screen-sm mx-auto transition-all duration-700`}>
      {Object.entries(groupedItems).map(([category, i]) => (
        <div key={category} className="mb-4">
          <h2 className="text-xl font-bold mb-2">{category}</h2>
          {i.map((item, index) => {
            const key = `${category}-${index}`
            return (
              <div key={key} className="mb-2">
                <div
                  onClick={() => toggleAccordion(category, index)}
                  className={`accordion-header text-left p-2 text-white cursor-pointer ${
                    openIndex === key ? 'rounded-t-lg' : 'rounded-lg'
                  }`}
                >
                  {item.title}
                  {item.hasStar && ' ⭐ '}
                </div>

                <div
                  className={`accordion-content ${
                    openIndex === key ? 'open' : ''
                  }`}
                >
                  <div
                    className={`p-2 border border-t-0 flex flex-col border-gray-300 rounded-b-lg bg-gray-100`}
                  >
                    <p className="text-gray-700 font-semibold">{item.info}</p>
                    <p className="text-gray-600">{item.description}</p>
                    {item.link && (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-inherit hover:underline ml-auto font-mono"
                        onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                        onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                      >
                        {isHovered
                          ? '→ Click to open new tab ←'
                          : '→ Click to check it out ←'}
                        {/* // Change text based on hover state */}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default AccordionList
