export const experienceList = [
  {
    id: 0,
    date: 'Early 2010s',
    title: 'Early Explorations',
    description:
      'Starting to understand basic HTML, CSS, and JavaScript on the frontend, and exploring PHP, Python, and Node.js for backend development. The first steps of my development journey.',
    icon: '🔍',
  },
  {
    id: 14,
    date: '2014',
    title: 'Jerusalem Clay Website',
    description:
      'My first website project using PHP, HTML, and CSS. A great learning experience to build a simple website from the ground up.',
    icon: '🌐',
    screenshot: '/assets/projects/websites/jerusalem.meir.pro-optimised.jpeg',
    scroll_speed: '4000',
    projectLink: 'https://Jerusalem.meir.pro',
    referrerLink: 'JerusalemClay',
    isWebsite: true,

    tech: [
      'PHP',
      'HTML',
      'JavaScript',
      'CSS',
      'SVG',
      'AJAX',
      'Image Optimization',
      'Responsive Images',
      'Emails & SMTP',
    ],
  },
  {
    id: 13,
    date: '2015',
    title: 'Soul Art from Safed Website',
    description:
      'Continued to expand my web development skills, this project was built using PHP, HTML, CSS, and JavaScript, and included a custom SVG system',
    icon: '🎨',
    projectLink: 'https://ruthknapp.com',
    referrerLink: 'RuthKnapp',
    longImage: '/assets/projects/websites/ruthknapp.com-long.webp',
    screenshot: '/assets/projects/websites/ruthknapp.com-optimised.jpeg',
    scroll_speed: '13000',
    isWebsite: true,

    project_description:
      'Based on the Holy Hebrew letters. Inspired from the teachings of Chassidut and Kabbalah. Letters of Light, Letters of Love.',
    tech: [
      'PHP',
      'HTML',
      'JavaScript',
      'jQuery',
      'BlueHost',
      'CSS',
      'SVG',
      'AJAX',
      'Cloudflare',
      'Turnstile Captcha',
      'Apache',
      'nginx',
    ],
  },
  {
    id: 31,
    date: '2015-2017',
    title: 'Computer Networking & Administration',
    description:
      'I studied computer networking and administration, learning both theory and hands-on skills. I gained a solid understanding of LAN/WAN, TCP/IP, and network management, complemented by completing Microsoft tests (MCSA). This knowledge has proven useful in various applications.',
    icon: '📚 🖥️ 👨‍💻',
  },
  {
    id: 32,
    date: '2017-2019',
    title: 'Practical Electrical Engineering Studies',
    description:
      'I studied Electrical Engineering, where I learned about circuits, power systems, and designing electrical grids. Practical projects helped me understand how systems function and how to solve related problems, giving me a solid foundation to apply these skills in real-world situations.',
    icon: '📚 ⚡️ 👷',
  },
  {
    id: 4,
    date: '2018-2022',
    title: 'Telegram Bots (see Other Projects section)',
    description:
      'Created multiple Telegram bots for personal and client use, using Node.js or Python and the Telegram bot API. Bots are able to perform a number of functions, such as external API look-ups, user interaction, supporting multiple languages, and more.',
    icon: '🤖',
    project_description: '',
  },
  // {
  //   id: 401,
  //   date: '2018-2022',
  //   title: 'Anime Search Telegram Bot',
  //   description:
  //     '🔍 A Telegram bot that allows users to search for anime using two different APIs and gathers the data to display using the Telegram Inline Search API. Written in Node.js, it reached 5000 users in the database before being shut down due to server costs. Since then, similar bots have taken its place. 🤖',
  //   icon: '🤖',
  //   screenshot: './assets/projects/telegram/AnimeFinder.webp',
  //   tech: ['Node.js', 'Telegram Bot API', 'Kitsu.io API', 'AniList API'],
  // },
  // {
  //   id: 402,
  //   date: '2020',
  //   title: 'Progress Tracker Telegram Bot',
  //   description:
  //     '📊 A Telegram bot that lets users track their progress on subjects they are interested in. Written in Python, it provides a user-friendly interface for monitoring and updating progress. 📈',
  //   icon: '🤖',
  //   screenshot: './assets/projects/telegram/timebot.webp',
  //   tech: ['Python', 'Telegram Bot API'],
  // },
  // {
  //   id: 403,
  //   date: '2020',
  //   title: 'Quick Help Support Bot',
  //   description:
  //     '🔧 A Telegram bot designed to provide quick help support for VR 360 Action machines, serving as the first step before reaching out to tech support. Written in Node.js, it streamlines the support process and improves user satisfaction. 🤖',
  //   icon: '🤖',
  //   screenshot: './assets/projects/telegram/VRSupportBot.webp',
  //   tech: ['Node.js', 'Telegram Bot API'],
  // },
  // {
  //   id: 404,
  //   date: '2022',
  //   title: 'ShamorVeZachor Bot',
  //   description:
  //     '📅 A Telegram bot that sends daily time notifications based on the Jewish calendar. It employs multiple APIs and techniques to provide accurate prayer times for each location. Users can customize the language 🇺🇸, location 📍, and timezone 🌍 settings to receive personalized updates. The bot uses OpenStreetMap to display maps and utilize geographical information. 🗺️',
  //   icon: '🤖',
  //   screenshot: './assets/projects/telegram/ShamorVeZachor.webp',
  //   tech: ['Node.js', 'Telegram Bot API', 'OpenStreetMap'],
  // },
  {
    id: 5,
    date: '2019-2024',
    title: 'VR 360 Action / OVRDRV Games CTO',
    description:
      'Oversaw the technical infrastructure of a company producing innovative virtual reality arcade machines, designed game systems, managed developers, and installed hardware across the United States.',
    icon: '🚀',
    projectLink: '/resume?pdf=show',
  },
  {
    id: 11,
    date: '2020',
    title: 'VR 360 Action Analytics Backend',
    description:
      'Developed a robust backend system, used by staff to track and monitor virtual reality machines. Includes live updates using WebSockets, JWT authentication, and a MongoDB database',
    icon: '⚙️',
    isWebsite: true,

    // projectLink: 'https://vip.vr360action.com', //if youre reading this, thats because I dont really mind someone checking out this link. otherwise I would not put it here...
    tech: [
      'Node JS',
      'WebSocket',
      'JWT',
      'MongoDB',
      'Analytics',
      'Heroku',
      'Express',
      'Bootstrap CSS',
      'Web-App',
      'CORS',
    ],
    screenshot: '/assets/projects/websites/vip.vr360action.com-optimised.jpeg',
    longImage: '/assets/projects/websites/vip.vr360action.com-long.webp',
    scroll_speed: '7000',
    referrerLink: 'VR360Action',
    note: 'This backend requires user authentication. Users are invited after purchasing machines that support this functionality.',
  },
  {
    id: 7,
    date: '2023-2024',
    title: 'Arcade Game Planning & Integration',
    description:
      'Led the planning and development of arcade games, ensuring engaging gameplay and seamless integration between hardware (motion/effects) and software. Designed APIs for communication, managed cross-functional teams, and implemented techniques like fans and scents to enhance immersion and prevent motion sickness. Oversaw testing and the full development lifecycle to deliver synchronized, high-quality gaming experiences.',
    icon: '💡',
  },
  {
    id: 12,
    date: '2024',
    title: 'Knapp Cartooning Studio',
    description:
      'A modern web project using ReactJS and TailwindCSS, utilizing many cutting edge techniques, and a focus on UI/UX design',
    icon: '✍️',
    projectLink: 'https://knappcartoons.com',
    screenshot: '/assets/projects/websites/knappcartoons.com-optimised.jpeg',
    longImage: '/assets/projects/websites/knappcartoons.com-long.webp',
    isWebsite: true,
    scroll_speed: '13000',
    referrerLink: 'KnappCartoons',
    project_description: "Custom caricature portraits by the Knapp artists'",
    tech: [
      'React JS',
      'Tailwind CSS',
      'Responsive UI',
      'SVG Graphics',
      'WebP Images',
      'Loading Optimization',
      'Mobile First',
      'SEO',
      'Google Captcha',
      'Intersection Observer',
      "Let's Encrypt",
    ],
  },
  {
    id: 202501,
    date: '2025',
    title: 'Meir.pro (this website)',
    description:
      'A portfolio built with ReactJS and TailwindCSS. This website is a constantly evolving project, reflecting my ongoing learning and development. Feel free to visit often for updates. Perhaps one day I will even have a mailing list; if you are interested in signing up early, please feel free to contact me. Thank you for visiting!',
    icon: '💻',
    // projectLink: 'https://meir.pro/?source=xpList',//no need for this
    // project_description: "Custom caricature portraits by the Knapp artists'",
    tech: [
      'React JS',
      'Tailwind CSS',
      'Responsive UI',
      'SVG Graphics',
      'WebP Images',
      'Loading Optimization',
      'Mobile First',
      'SEO',
      'Intersection Observer',
    ],
  },

  {
    id: 10,
    date: 'Present',
    title: 'Focused on the Future',
    description:
      'Currently dedicated to mastering advanced software development techniques, exploring AI/ML applications, and building scalable solutions. I am also focused on personal and business growth, aiming to create impactful and innovative projects, while also appreciating the present moment and the journey of learning and growth.',
    icon: '🚀',
  },
]
