// import usePageLoad from "../../utils/usePageLoaded";
import "./Nav.css";
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Link, useLocation } from 'wouter'
import { trackEvent } from '../../utils/gtagTracking'

const MyNavLink = ({ to, text }) => {
  const [location] = useLocation()
  const isActive = location === to

  return (
    <li className="px-3 select-none" title={text}>
      <Link
        to={to}
        onClick={trackEvent({
          eventName: 'navigation_click',
          label: text,
          category: 'navigation',
        })}
        // replace={true}
        // state={{ animate: true }}
        className={`hover:text-green-700 ${isActive ? 'text-green-500' : ''}`}
      >
        {text}
      </Link>
    </li>
  )
}

export const Nav = () => {
  return (
    <>
      <header className="max-w-screen-lg m-auto min-h-[10vh]">
        <nav className="px-8 py-4 flex flex-col md:flex-row justify-center sm:justify-between items-center max-w-screen-xl mx-auto">
          <Link to="/" title="Home">
            <div className="logo">
              <img
                src={'/assets/MeirLogo.svg'}
                alt="Meir Profile"
                className="size-16"
              />
            </div>
          </Link>
          <ul className="flex p-4 text-xl font-lucky tracking-wide mt-2 sm:mt-1 flex-wrap text-center justify-center">
            <MyNavLink to="/" text="Home" />
            <MyNavLink to="/about" text="About" />
            <MyNavLink to="/contact" text="Contact" />
            {/* <MyNavLink to="/wip" text="Experiments" /> */}
            {/* <MyNavLink to="/tools" text="Tools" /> */}
            <MyNavLink to="/resume" text="Resume" />
            {/* <MyNavLink to="/ai" text="AI" /> */}
          </ul>
        </nav>
      </header>
      {/* <RouterProvider router={router} /> */}
      {/* <RouterProvider router={router} fallbackElement={<LoadingPage />} /> */}
      {/* <Router> */}
    </>
  )
}
