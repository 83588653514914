// import markdownContent from "../markdown/list.md";
// import MyMarkdownComponent from "../utils/markdown";
import React from "react";
// eslint-disable-next-line no-unused-vars
// import { ExistingWork } from "./hero/ExistingWork";
// import { ContactBody } from './Contact'
import { Hero } from './hero'
// import ProjectList from "./projectsList";

const Index = () => {
  return (
    <div className="flex flex-col text-center">
      {/* <ProjectList /> */}
      {/* <ExistingWork /> */}
      <Hero />
      {/* <div className="mt-20 px-4">
        <ContactBody />
      </div> */}
    </div>
  )
}

export default Index;
