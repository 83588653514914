import React from 'react'
import AccordionList from '../components/AccordionList'
import Heading from '../components/Heading'
import { ContactBody } from './Contact'

//can add a hidden field, only if url has #hidden or something, show it
const showItemsKey = 'a'
const generateItems = (quickShare, secretKey) => {
  const items = [
    {
      title: 'drop.lol',
      description:
        'Drop.lol is a peer-to-peer file sharing service that allows users to share files directly between browsers without the need for a server. It supports file sharing with end-to-end encryption and is designed for quick and easy transfers, making it ideal for any use.',
      tags: ['File Sharing', 'Chat', 'Peer-to-Peer', 'Encryption'],
      link: `https://drop.lol/${quickShare ? quickShare : ''}`,
      open: quickShare ? true : false,
      hasStar: true,
    },
    {
      title: 'wormhole.app',
      description:
        'Wormhole allows you to share files with end-to-end encryption and a link that automatically expires. You can send files up to 10 GB easily, making it a secure and convenient option for file sharing.',
      tags: ['File Sharing', 'Encryption', 'Secure Sharing', 'Temporary Links'],
      link: `https://wormhole.app/${quickShare ? quickShare : ''}`,
    },
    {
      title: 'Snapdrop.net',
      description: 'A peer-to-peer file sharing application.',
      link: 'https://snapdrop.net',
      tags: ['File Sharing', 'WebRTC'],
    },
    {
      title: 'T.LY',
      description:
        'T.LY is a URL shortening service that allows you to create short links quickly and easily. It offers features like link tracking, custom aliases, and analytics to monitor link performance.',
      tags: ['URL Shortener', 'Link Tracking', 'Analytics'],
      link: 'https://t.ly/',
      hasStar: true,
    },
    {
      title: 'Kutt.it',
      description: 'An open-source URL shortener.',
      info: 'I want to develop a URL shortener that offers a user-friendly interface for creating short links. It will include analytics to track link performance and user engagement.',
      link: 'https://kutt.it',
      tags: ['URL Shortener', 'Link Tracking', 'Analytics'],
    },
    {
      title: '#TODO - QR Code Generator',
      description: 'A tool for generating QR codes.',
      info: 'This simple yet effective tool will generate QR codes for URLs and text, making it easy for you to share information quickly and efficiently through scannable codes.',
      tags: ['#TODO', 'QR Code', 'Sharing'],
      hidden: true, //need to get a good tool link
    },
    {
      title: 'Link Tracking Service',
      description: 'A service for tracking shortened links.',
      info: 'This service will enable you to create and track shortened links. I want to provide detailed analytics on link performance, including click rates and user demographics.',
      link: 'https://grabify.link/faq/features',
      hidden: true,
      tags: ['URL Shortener', 'Analytics', 'Link Tracking'],
    },
    {
      title: '#TODO Secret Chat Using WebSocket and Encryption',
      description: 'A real-time chat application.',
      info: 'I want to create a real-time chat application that uses WebSocket technology for instant messaging. All communications will be secured with end-to-end encryption, ensuring your privacy is protected.',
      tags: ['#TODO', 'Chat', 'WebSocket', 'Encryption'],
      hidden: true,
    },
    {
      title: 'Squoosh.app - Image Editor / Optimizer',
      description: 'A web app for optimizing images.',
      info: 'Squoosh allows you to compress and optimize images for the web, making them smaller without losing quality.',
      tags: ['Image Optimization', 'WEBP', 'JPEG', 'PNG'],
      link: 'https://squoosh.app',
      hasStar: true,
    },
    {
      title: 'SVGOMG - SVG Optimizer',
      description: 'A web-based tool for optimizing SVG files.',
      info: 'SVGOMG is powered by SVGO and allows you to clean up and optimize your SVG files for better performance.',
      tags: ['Image Optimization', 'SVG'],
      link: 'https://jakearchibald.github.io/svgomg/',
      hasStar: true,
    },
    {
      title: 'OSINT - People',
      description:
        'A powerful tool for collecting and analyzing open-source information from Telegram.',
      info: 'This tool helps you gather and analyze open-source intelligence from various online leaks and groups.',
      tags: ['Telegram', 'OSINT', 'People'],
      link: 'https://t.me/LightSearchBot/',
      hasStar: true,
      hidden: true,
    },
    {
      title: 'OSINT - Telegram',
      description:
        'A powerful tool for collecting and analyzing open-source information from Telegram.',
      info: 'This tool helps you gather and analyze open-source intelligence from various Telegram channels and groups.',
      tags: ['Telegram', 'OSINT', 'TG Messages'],
      link: 'https://t.me/LightFunStatsBot/',
      hasStar: true,
      hidden: true,
    },
    {
      title: 'QRBTF - AI & Parametric QR Code Generator (Free 10/day)',
      description:
        'Generate QR codes with AI and parametric controls. Gives you 10 free tries / day.',
      tags: ['QR Code', 'AI', 'Parametric'],
      link: 'https://qrbtf.com/en',
      hasStar: true,
    },
    {
      title: 'QuickQR Art Generator',
      description: 'Generate QR codes with AI and preset controls.',
      tags: ['QR Code', 'AI', 'More...'],
      link: 'https://QuickQR.art/app/generate',
      hasStar: false,
    },
    {
      title: 'Gooey.AI - AI QR Code Generator',
      description: 'Generate artistic QR codes using AI.',
      tags: ['QR Code', 'AI', 'Artistic'],
      link: 'https://gooey.ai/qr-code',
      hasStar: false,
    },
    {
      title: 'ChatGPT',
      description: 'A powerful and versatile language model from OpenAI.',
      tags: ['AI', 'Chat', 'Language Model'],
      link: 'https://chatgpt.com',
    },
    {
      title: 'Claude',
      description: 'An AI assistant focused on helpfulness and harmlessness.',
      tags: ['AI', 'Chat', 'Language Model'],
      link: 'https://claude.ai/',
    },
    {
      title: 'Google AI Studio (try beta models for free)',
      description:
        "Google's platform for experimenting with their Gemini models.",
      tags: ['AI', 'Language Model', 'Google'],
      link: 'https://aistudio.google.com/prompts/new_chat',
    },
    {
      title: 'LM Arena - AI Models Competition (free)',
      description:
        'A platform to compare and evaluate different language models.',
      tags: ['AI', 'Language Model', 'Comparison'],
      link: 'https://web.lmarena.ai/',
    },
    {
      title: 'Perchance (free, no login)',
      description:
        'A collection of free, browser-based AI tools, including a text-to-image generator.',
      tags: ['AI', 'Text-to-Image', 'Free'],
      link: 'https://perchance.org/ai-text-to-image-generator',
    },
    {
      title: 'OpenRouter',
      description:
        'An API for accessing various AI models through a single integration.',
      tags: ['AI', 'API', 'Language Model'],
      link: 'https://openrouter.ai/',
    },
    {
      title: 'Qwen',
      description: 'A language model developed by Alibaba.',
      tags: ['AI', 'Language Model', 'Alibaba'],
      link: 'https://chat.qwenlm.ai/',
    },
    {
      title: 'cryptii',
      description:
        'A web app for modular conversion, encoding, and encryption.',
      tags: ['Encryption', 'Encoding', 'Conversion'],
      link: 'https://cryptii.com/',
      hasStar: true,
    },
  ]

  // Filter items based on hidden key and secretKey
  return items.filter((item) => !item.hidden || secretKey === showItemsKey)
}

const OnlineTools = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const secretKey = urlParams.get('secret')
  const quickShare = urlParams.get('share')

  const items = generateItems(quickShare, secretKey)

  return (
    <div className="selection:text-white selection:bg-mantis-600 max-w-screen-md mx-auto px-2">
      <div className="mb-4 text-center ">
        <Heading>My Favorite Online Tools</Heading>
        <p className="text-gray-700 font-normal my-8">
          I use these tools frequently and find them to be incredibly helpful in
          my daily work. Feel free to explore and check them out - You might
          find something that suits your needs.
        </p>
      </div>
      <AccordionList items={items} pageThemeColor="#7dca6e" />
      <div className="mt-20">
        <ContactBody />
      </div>
    </div>
  )
}

export default OnlineTools
