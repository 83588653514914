// import Intro from './Intro'

// export const Hero = () => {
//   return (
//     <>
//       <Intro />
//     </>
//   )
// }

import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'wouter'
import { useLocalStorage } from '@uidotdev/usehooks'
import Intro from './Intro'
// import Timeline from './Timeline'
// import ProjectsSection from './ProjectsSection'
// import SkillsSection from './SkillsSection'
import ContactSection from './ContactSection'
import Experiences from './Experiences'
import { ExistingWork } from './ExistingWork'
import { experienceList } from '../ExperienceList'
import CareerHighlights from './CareerHighlights'
import OtherProjectsSection from './OtherProjects'

export const Hero = () => {
  const [location] = useLocation()
  const referrer = new URLSearchParams(location.split('?')[1]).get('s')
  const [visitedProjects, setVisitedProjects] = useLocalStorage(
    'visitedProjects',
    []
  )

  //   Add referrer to visited projects if it matches a project
  useEffect(() => {
    if (referrer) {
      const referredProject = experienceList.find(
        (p) => p.referrerLink === referrer
      )
      if (referredProject && !visitedProjects.includes(referredProject.id)) {
        setVisitedProjects([...visitedProjects, referredProject.id])
      }
    }
  }, [referrer, visitedProjects, setVisitedProjects])

  const [clickedImageId, setClickedImageId] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [hoveredImageId, setHoveredImageId] = useState(null)
  const scrollContainerRefs = useRef({})
  const isUserScrolling = useRef(false)
  // eslint-disable-next-line no-unused-vars
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (clickedImageId) return
    const container = scrollContainerRefs.current[hoveredImageId]
    if (!container) return

    let startTime
    let animationFrameId
    const duration =
      experienceList.find((item) => item.id === hoveredImageId)?.scroll_speed ||
      5000

    const handleUserScroll = () => {
      isUserScrolling.current = true
      // Cancel animation
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
      // Remove scroll listener after it's triggered
      container.removeEventListener('wheel', handleUserScroll)
      container.removeEventListener('touchmove', handleUserScroll)
    }

    // Add scroll listeners
    container.addEventListener('wheel', handleUserScroll)
    container.addEventListener('touchmove', handleUserScroll)

    const animate = (currentTime) => {
      if (isUserScrolling.current) return

      if (!startTime) startTime = currentTime
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1)

      const totalScroll = container.scrollHeight - container.clientHeight
      container.scrollTop = totalScroll * progress

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animate)
      }
    }

    animationFrameId = requestAnimationFrame(animate)

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
      container.scrollTop = 0
      isUserScrolling.current = false
    }
  }, [hoveredImageId, clickedImageId])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleVisitSite = (url, projectTitle, projectId) => {
    // Add project to visited list if not already there
    if (!visitedProjects.includes(projectId)) {
      setVisitedProjects([...visitedProjects, projectId])
    }
    window.open(url, '_blank')
  }

  return (
    <div className="p-8 mx-auto relative">
      <Intro />

      <CareerHighlights />

      <Experiences
        experienceList={experienceList}
        handleVisitSite={handleVisitSite}
        setClickedImageId={setClickedImageId}
        clickedImageId={clickedImageId}
        isUserScrolling={isUserScrolling}
        scrollContainerRefs={scrollContainerRefs}
        setHoveredImageId={setHoveredImageId}
      />

      {/* <ProjectsSection
        projects={projects}
        handleVisitSite={handleVisitSite}
        clickedImageId={clickedImageId}
        setClickedImageId={setClickedImageId}
        setHoveredImageId={setHoveredImageId}
        scrollContainerRefs={scrollContainerRefs}
        isUserScrolling={isUserScrolling}
        referrer={referrer}
        visitedProjects={visitedProjects}
        setVisitedProjects={setVisitedProjects}
      /> */}
      <ExistingWork />
      <OtherProjectsSection />

      {/* <SkillsSection /> */}

      <ContactSection />
    </div>
  )
}
