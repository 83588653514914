// Intro.js
import React from 'react'
// import { FaGithub, FaLinkedin } from 'react-icons/fa'
import Heading from '../../components/Heading'

const Intro = () => {
  return (
    <section className="mb-16 text-center">
      <Heading addedClasss="!text-4xl font-bold mb-4">Hi, I'm Meir!</Heading>
      <Heading addedClasss="!text-2xl font-bold mb-4 ">
        I'm passionate about creating, building, and improving.
      </Heading>
      <p className="text-lg text-slate-700 mb-8">
        I bring a focused and enthusiastic approach to everything I do. Whether
        it's web development, systems engineering, or project planning, I'm
        committed to continuous improvement and quality.
      </p>
      {/* <div className="flex justify-center space-x-4 mb-4">
        <a
          href="https://github.com/MeirKnapp"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-800 transition-colors duration-300"
        >
          <FaGithub size={30} />
        </a>
        <a
          href="https://www.linkedin.com/in/meir-knapp-442a73211/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-800 transition-colors duration-300"
        >
          <FaLinkedin size={30} />
        </a>
      </div> */}
      <picture>
        <source srcSet="./assets/home/Meir-at-desk.webp" type="image/webp" />
        <img
          src="./assets/home/Meir-at-desk.png"
          alt="Meir Knapp sitting by his virtual desk"
          className="w-96 h-auto mx-auto mb-8"
          style={{
            maxWidth: '90%',
            height: 'auto',
          }}
        />
      </picture>
      <p className="text-md text-slate-700">
        Lets create something awesome together!
      </p>
    </section>
  )
}

export default Intro
