// ProfessionalSummary.js
import React from 'react'
import Heading from '../../components/Heading'

const accomplishments = [
  {
    category: 'Systems Optimization',
    description:
      'I developed customized solutions, such as a streamlined Windows OS installation package, which reduced machine setup time by 80-90%.',
    badgeColor: 'bg-blue-100 text-blue-800',
  },
  {
    category: 'Communication Infrastructure',
    description:
      'I designed and implemented comprehensive API protocols, enabling seamless collaboration across multiple developer teams.',
    badgeColor: 'bg-green-100 text-green-800',
  },
  {
    category: 'Backend Infrastructure',
    description:
      'I established centralized backend servers for file management and analytics, creating scalable and reliable systems.',
    badgeColor: 'bg-yellow-100 text-yellow-800',
  },
  {
    category: 'Network Architecture',
    description:
      'I implemented secure internal LAN networks and external firewall configurations, ensuring robust connectivity and security.',
    badgeColor: 'bg-red-100 text-red-800',
  },
  {
    category: 'Technical Design',
    description:
      'I engineered VR arcade machine systems by creating communication protocols between game components, hardware, and software launchers.',
    badgeColor: 'bg-purple-100 text-purple-800',
  },
]

const CareerHighlights = () => {
  return (
    <section className="bg-gray-100 p-6 rounded-lg max-w-4xl mx-auto text-center">
      <Heading addedClasss={'!text-4xl !text-slate-800 mb-4'}>
        Career Highlights
      </Heading>
      <h2 className="text-xl pb-4">VR 360 Action & OVRDRV Games</h2>

      <div className="text-start">
        <p className="text-gray-700 mb-4">
          Throughout my career, I consistently lead, innovate, and optimize
          processes and products. My strengths include software and systems
          architecture, team management, and technical design and
          implementation.
        </p>

        <div className="overflow-x-auto">
          <table className="table-auto border-collapse w-full text-gray-700 gap-4">
            <tbody>
              {accomplishments.map((item, index) => (
                <tr key={index} className="flex flex-col xs:table-row">
                  <td className="py-2 pr-4 align-middle text-center">
                    <div className="flex items-center justify-center h-full xs:w-full">
                      <span
                        className={`${item.badgeColor} font-semibold px-2 py-1 xs:w-full rounded`}
                      >
                        {item.category}
                      </span>
                    </div>
                  </td>
                  <td className="py-2">{item.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <p className="text-gray-700 mt-4 pt-4  border-t-2">
        Having been promoted from Technician/Engineer to Chief Technology
        Officer (CTO), I continue to leverage my expertise to drive innovation
        and deliver impactful results.
      </p>
    </section>
  )
}

export default CareerHighlights
